import React from 'react';

const RateItem = ({ children }) => (
  <li className='col-xs-12 col-lg-4 rate-item'> 
    <div className='card'>
      {children}
    </div>
  </li>
);

export default RateItem;