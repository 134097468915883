import React from "react";

import GeneralAnchor from "../general-anchor/general-anchor.component";

// import HeroImageMaintanence from '../../assets/images/hero-maintanence-image_2021@1x.jpeg';
// import HeroImageMaintanence2X from '../../assets/images/hero-maintanence-image_2021@2x.jpg';
import HeroImage1X from "../../assets/images/hero-image_2021@1x.jpg";
import HeroImage2X from "../../assets/images/hero-image_2021@2x.jpg";
import HeroImage4X from "../../assets/images/hero-image_2021@3x.jpg";
import HeroBannerMaintenance from "./hero-banner-maintenance.component";
import HeroBannerVideo from "./hero-banner-video.component";
import HeroBannerImportant from "./hero-banner-important.component";
import FASA from "../../assets/images/FASA-2021-WINNER-LOGOS_5starWinner_21.jpg";
import FASA2X from "../../assets/images/FASA-2021-WINNER-LOGOS_5starWinner_21@2x.jpg";
import FASA3X from "../../assets/images/FASA-2021-WINNER-LOGOS_5starWinner_21@3x.jpg";
let multicolumnPictureTitle = "FASA";

const MulticolumnPicture = () => {
  return (
    <picture className="hero-banner__image">
      <img
        srcSet={`${FASA} 1x, ${FASA2X} 2x, ${FASA3X} 3x,`}
        src={FASA}
        type="image/jpeg"
        alt={`${multicolumnPictureTitle}`}
      />
    </picture>
  );
};

const getHeroBannerClass = (heroBannerType, isMaintanence, isVideo, baseClass = "hero-banner") => {
  if (heroBannerType === "multicolumn") {
    baseClass += ` ${baseClass}--multicolumn`;
  } else if (heroBannerType === "full" && isMaintanence) {
    baseClass += ` ${baseClass}--maintenance maintanence`;
  } else if (heroBannerType === "full" && isVideo) {
    baseClass += ` ${baseClass}--full autumn`;
  } else if (heroBannerType === "full") {
    baseClass += ` ${baseClass}--full`;
  }
  return baseClass;
};

const HeroBanner = ({
  isCtaShown = false,
  isMaintanence = false,
  isVideo = false,
  isImportant = true,
  heroBannerType = isMaintanence || isVideo || isImportant ? "full" : "multicolumn",
}) => {
  let heroBannerClass = getHeroBannerClass(heroBannerType, isMaintanence);

  if (isMaintanence) {
    return <HeroBannerMaintenance heroBannerClass={heroBannerClass} />;
  } else if (isVideo) {
    return <HeroBannerVideo heroBannerClass={heroBannerClass} />;
  } else if (isImportant) {
    return <HeroBannerImportant heroBannerClass={heroBannerClass} />;
  } else {
    return (
      <HeroBannerDefault heroBannerType={heroBannerType} isCtaShown={isCtaShown} heroBannerClass={heroBannerClass} />
    );
  }
};
const HeroBannerDefault = ({ heroBannerType, isCtaShown, heroBannerClass }) => {
  return (
    <div className={`${heroBannerClass}`}>
      <img
        className="hero-banner__background-image"
        srcSet={`${HeroImage1X} 1x, ${HeroImage2X} 2x, ${HeroImage4X} 3x`}
        type="image/jpeg"
        alt=""
      />
      <div className="hero-banner__outer">
        <section className="hero-banner__content">
          <p className="first">
            <span>5* reasons to use</span> <span>HSBC UK</span>
          </p>
          <p className="second">
            We are thrilled to have received the prestigious 5 star award for our exceptional service.
          </p>
          <p className="second">Thank you for voting for us and for your ongoing support.</p>
          {isCtaShown && (
            <GeneralAnchor isRed href="http://www.intermediaries.hsbc.co.uk/products/product-finder.html">
              View product finder
            </GeneralAnchor>
          )}
        </section>
        {heroBannerType === "multicolumn" ? <MulticolumnPicture /> : ""}
      </div>
    </div>
  );
};

export default HeroBanner;
