import React from "react";
import RelatedLink from "../related-link/related-link.component";
import RELATED_LINKS_SCHEME from "./related-lins.utils";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

export default function RelatedLinks() {
  const location = useLocation();

  const handleClick = (relatedLink) => {
    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "button",
      eventContent: relatedLink,
      pageType: "product information",
      pageSubCategory: "Home",
      rawDataLayer: "10451v5",
    });
  };

  return (
    <section className="related-links row">
      {RELATED_LINKS_SCHEME.length &&
        RELATED_LINKS_SCHEME.map((card, index) => (
          <RelatedLink key={index} {...card} onClick={(relatedLink) => handleClick(relatedLink)} />
        ))}
    </section>
  );
}
