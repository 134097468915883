const LATEST_NEWS_SCHEME = [
  {
    title: 'The Intermediary: The Interview… Chris Pearson, head of intermediary mortgages, HSBC UK',
    href: '/newsroom/essential-reading/08-april-the-interview-chris-pearson-head-of-intermediary-mortgages'
  },
  {
    title: 'Financial Reporter: HSBC UK hits 750 broker milestone',
    href: '/newsroom/essential-reading/04-april-2022-hsbc-uk-hits-750-broker-milestone'
  },
  {
    title: 'Financial Reporter: In The Spotlight with Chris Pearson, HSBC UK',
    href: '/newsroom/essential-reading/24-september-2021-in-the-spotlight-with-chris-pearson-hsbc-uk'
  },
  {
    title: 'HSBC UK cuts 20 mortgage rates and increases lending limits',
    href: '/newsroom/essential-reading/18-march-2021-hsbc-uk-cuts-20-mortgage-rates-and-increases-lending-limits'
  },
  {
    title: 'HSBC UK cuts 15 mortgage rates and comments on Budget announcements',
    href: '/newsroom/essential-reading/4-march-2021-hsbc-uk-cuts-15-mortgage-rates-and-comments-on-budget-announcements/'
  },
  {
    title: 'HSBC UK updates mortgage rates and bonus payments',
    href: '/newsroom/essential-reading/19-february-2021-hsbc-uk-updates-mortgage-rates-and-bonus-payments/'
  },
  {
    title: 'Comment from HSBC UK’s Head of Intermediary Mortgages, Chris Pearson',
    href: '/newsroom/essential-reading/19-february-2021-comment-from-chris-pearson/'
  },
  {
    title: 'Accelerated onboarding helps HSBC UK hit broker milestone',
    href: '/newsroom/essential-reading/19-february-2021-accelerated-onboarding-helps-hsbc-uk-hit-broker-milestone/'
  }
];

export default LATEST_NEWS_SCHEME;