import MAW2023 from "../../assets/images/MortgageAwardsWinners2023-Mortgage_Lender_Customer_Service_of_the_Year.jpg";
import MAWB2023 from "../../assets/images/MortgageAwardsWinners2023-Bank_Mortgage_Provider_of_the_Year.jpg";
import LGW2023 from "../../assets/images/16.L&G.2023.WINNERS_logos_over£1bn_HSBC.png";
import YMA from "../../assets/images/YMA22-23_WINNER_best.remort_hsbc.jpg";

const AWARDS = [
  {
    title: ["L&G Mortgage Club Awards"],
    date: "June 2023",
    subTitles: ["Best Large Lender - Over £1bm in lending"],
    imgSrc: LGW2023,
    id: 48,
  },
  {
    title: ["MoneyAge The Mortgage Awards"],
    date: "June 2023",
    subTitles: ["Bank Mortgage Provider of the Year"],
    imgSrc: MAWB2023,
    id: 47,
  },
  {
    title: ["MoneyAge The Mortgage Awards"],
    date: "June 2023",
    subTitles: ["Mortgage Lender Customer Service of the Year"],
    imgSrc: MAW2023,
    id: 46,
  },
  {
    title: ["YourMortgage Awards"],
    date: "November 2022",
    subTitles: ["Best Remortgage Lender"],
    imgSrc: YMA,
    id: 45,
  },
];

export default AWARDS;
