import React from 'react';

import Faq from '../faq/faq.component';
import LatestNews from '../latest-news/latest-news.component';

const FaqNewsPanel = () => (
  <section className='faq-news-container'>
    <div className='row'>
      <div className='col-xs-12 col-lg-7'>
        <Faq/>
      </div>
      <div className='col-xs-12 col-lg-4 col-lg-offset-1'>
        <LatestNews/>
      </div>
    </div>
  </section>
);

export default FaqNewsPanel;