import React from "react";
import GeneralAnchor from "../general-anchor/general-anchor.component";
import Calculator from "../../assets/calculator.inline.svg";
import NewWindow from "../../assets/new_window.inline.svg";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

const AffordabilityCalculator = ({ children }) => {
  const location = useLocation();

  const handleClick = (calculatorType) => {
    if (typeof window === "undefined" || !window.utag) return;

    const currentPath = location.href.split("/").pop() || "home";

    const eventDetails = {
      home: {
        pageSubCategory: "Home",
        rawDataLayer: "10451v6",
      },
      criteria: {
        pageName: "criteria",
        pageSubCategory: "criteria",
        rawDataLayer: "10451v16",
      },
    };

    const config = eventDetails[currentPath];
    if (!config) {
      return;
    }

    const { pageName = "", pageSubCategory, rawDataLayer } = config;

    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "button",
      eventContent: calculatorType,
      pageType: "product information",
      pageName,
      pageSubCategory,
      rawDataLayer,
    });
  };

  return (
    <section className="affordability-calculator">
      <div className="affordability-calculator__texts">
        <Calculator />
        <h2>Calculators</h2>
        <p>Find out how much your customer may be able to borrow with these quick and easy-to-use calculators.</p>
      </div>
      <div className="affordability-calculator__buttons">
        <GeneralAnchor
          isBlank
          href="https://portal.intermediaries.hsbc.co.uk/affordabilitycalculator/affordabilitycalculatorpage.php"
          rel="noreferrer"
          isLink={true}
          additionalClass="residential-calculator"
          onClick={() => handleClick("Launch Residential calculator")}
        >
          Launch Residential calculator
          <NewWindow />
        </GeneralAnchor>
        <GeneralAnchor
          isBlank
          href="https://www.hsbc.co.uk/mortgages/btl-calculator/"
          rel="noreferrer"
          isLink={true}
          onClick={() => handleClick("Launch Buy to let calculator")}
        >
          Launch Buy to let calculator
          <NewWindow />
        </GeneralAnchor>
        {children && children}
      </div>
    </section>
  );
};

export default AffordabilityCalculator;
