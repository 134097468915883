import React, { useContext } from "react";
import { Link } from "gatsby";

import { MenuContext } from "../../context";

const BrokerSupport = () => {
  const menuContext = useContext(MenuContext);

  return (
    <section className="row">
      <div className="col">
        <div className="broker-support ">
          <Link to="/broker-support" inert={menuContext.isMenuOpen ? "true" : null}>
            <h2>Broker support</h2>
          </Link>
          <p>It's easy to get in touch online. Talk to us directly through our chat channels.</p>
        </div>
      </div>
    </section>
  );
};

export default BrokerSupport;
