import React from "react";
import RateItem from "../rate-item/rate-item.component";

import RATE_SCHEME from "./rate.utils";

const Rate = () => (
  <section className="rate">
    <div className="row">
      <div className="col">
        <div className="row">
          <h2>5 Year Fixed Standard Existing Mortgage Customer</h2>
        </div>
        <ul className="row">
          {RATE_SCHEME.length &&
            RATE_SCHEME.map((rate, index) => <RateItem children={rate} index={index} key={index} />)}
        </ul>
      </div>
    </div>
  </section>
);

export default Rate;
