import React from "react";

import GeneralAnchor from "../general-anchor/general-anchor.component";

import CriteriaIcon from "../../assets/lending-crit.inline.svg";
//import MessageIcon from '../../assets/message.inline.svg';
import SearchIcon from "../../assets/search.inline.svg";
import StepByStep from "../../assets/stepbystep.inline.svg";

const renderIconByType = (iconType) => {
  switch (iconType) {
    case "CriteriaIcon":
      return <CriteriaIcon />;
    case "StepByStep":
      return <StepByStep />;
    default:
      return <SearchIcon />;
  }
};

const RelatedLink = ({ icon, href, title, description, onClick }) => (
  <div className="related-link col-xs-12 col-lg-4">
    {renderIconByType(icon)}
    <GeneralAnchor href={href} onClick={() => onClick(title)}>
      <h3>{title}</h3>
    </GeneralAnchor>
    <p>{description}</p>
  </div>
);

export default RelatedLink;
