import React from "react";
import { Link } from "gatsby";
import { SLA_RESIDENTAL_EMPLOYED, SLA_RESIDENTAL_SELF_EMPLOYED, SLA_BUY_TO_LET } from "../../data/sla.data";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

const ServiceStrip = () => {
  const location = useLocation();

  const handleClick = () => {
    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "button",
      eventContent: "Service levels",
      pageType: "product information",
      pageSubCategory: "Home",
      rawDataLayer: "10451v5",
    });
  };

  return (
    <section className="row section">
      <div className="col">
        <div className="service-strip service-strip--single-line">
          <Link
            to="/our-latest-service-levels"
            rel="noreferrer"
            className="homepage-link-titles"
            onClick={() => handleClick()}
          >
            <h2 className="section__title">Service levels</h2>
          </Link>
          <div className="content">
            <p>
              {SLA_RESIDENTAL_EMPLOYED.title}
              <span>{SLA_RESIDENTAL_EMPLOYED.value}</span>
            </p>
            <div className="dot"></div>
            <p>
              {SLA_RESIDENTAL_SELF_EMPLOYED.title}
              <span>{SLA_RESIDENTAL_SELF_EMPLOYED.value}</span>
            </p>
            <div className="dot"></div>
            <p>
              {SLA_BUY_TO_LET.title}
              <span>{SLA_BUY_TO_LET.value}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceStrip;
