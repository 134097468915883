import React from "react";
import BannerImage from "../../assets/images/banner_green.jpg";
import { Link } from "gatsby";
import NewWindow from "../../assets/new_window.inline.svg";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

const LinkToGreenPage = () => {
  const location = useLocation();

  const handleClick = () => {
    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "button",
      eventContent: "Sustainability",
      pageType: "product information",
      pageSubCategory: "Home",
      rawDataLayer: "10451v5",
    });
  };
  return (
    <section className="green-banner-container">
      <img src={BannerImage} alt="" className="green-banner-container__background-image" />
      <div className="green-banner-content">
        <Link className="homepage-link-titles" to="/green" onClick={() => handleClick()}>
          <h2 className="section__title">Sustainability</h2>
        </Link>
        <p className="green-banner-container__description">
          The issue of climate change is bigger than all of us, but with a collective effort it is not too huge to
          tackle.
          <br />
          <br />
          <Link to="/green" style={{ textDecoration: "underline" }}>
            Click here
          </Link>{" "}
          to see useful tools, resources and articles which provide guidance to help adapt your business for the future,
          support your customers, and transition towards a net-zero economy.
          <br />
          <br />
          Today, HSBC finances a number of industries that significantly contribute to greenhouse gas emissions. We have
          a strategy to help our customers to reduce their emissions and to reduce our own. For more information visit{" "}
          <a
            target="_blank"
            href="https://www.hsbc.com/who-we-are/our-climate-strategy"
            style={{ textDecoration: "underline" }}
          >
            www.hsbc.com/sustainability
            <NewWindow style={{ marginBottom: "-3px", marginLeft: "3px" }} />
          </a>
          .
        </p>
      </div>
    </section>
  );
};

export default LinkToGreenPage;
