import React from "react";
/* import HomepageBanner from "../../assets/images/msa_homepage_background.jpg";
import ExternalLink from "../../components/external-link/external-link.component";
import MortgageStrategyAwards from "../../assets/images/mortgage_strategy_awards.gif"; */
import HeroWinterBg from "../../assets/images/hero-winter-bg.jpg";
import SnowGlobe from "../../../static/videos/snowglobe.mp4";

const HeroBannerImportant = () => {
  return (
    <div className="hero-banner hero-banner--maintenance ">
      <img
        className="hero-banner__background-image"
        src={HeroWinterBg}
        type="image/jpeg"
        alt="High Value Mortgage Service Banner Background"
      />
      <div className="hero-banner__outer">
        <div className="hero-banner__side-image">
          <video autoPlay loop muted playsInline width="100%" height="100%" >
            <source src={SnowGlobe}></source>
          </video>
        </div>
        <section className="hero-banner__content ">
          <p>
            Wishing all our brokers a 
            <br />Merry Christmas and a 
            <br />Happy New Year, however you’re celebrating. 
            <br />Thank you for all your support this year and here’s to a prosperous 2025.
          </p>
        </section>
      </div>
    </div>
  );
};

export default HeroBannerImportant;
