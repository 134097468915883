import React from "react";

import GeneralAnchor from "../general-anchor/general-anchor.component";

const RATE_SCHEME = [
  [
    <React.Fragment key="1">
      <strong style={{ paddingBottom: "5px" }}>4.03%</strong>
      <p>Fixed rate until 28/02/2030</p>
      <p>Maximum LTV 60%, maximum loan size &pound;5m.</p>
      <p>Booking fee &pound;999</p>
    </React.Fragment>,
  ],
  [
    <React.Fragment key="2">
      <p>Reverting to the HSBC</p>
      <p>variable rate, currently</p>
      <p>
        <strong style={{ lineHeight: "42px", paddingRight: "10px" }}>6.99%</strong> for the rest of the term
      </p>
    </React.Fragment>,
  ],
  [
    <React.Fragment key="3">
      <p>Overall cost for comparison:</p>
      <p>
        <strong style={{ lineHeight: "42px", paddingRight: "10px", fontWeight: "300" }}>6.1% APRC</strong>
      </p>
      <span>
        <GeneralAnchor additionalClass="rate-anchor-btn" href="/products/product-finder">
          View product
        </GeneralAnchor>
      </span>
    </React.Fragment>,
  ],
];

export default RATE_SCHEME;
