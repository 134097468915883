import React from 'react';
import AwardCard from '../award-card/award-card.component';

const AwardList = ({ awardItems=[], additionalClass, orientation }) => (
  <div className='row award-cards'>
  {
    awardItems.map((awardItem, index) => (
      <AwardCard
        key={index}
        additionalClass={additionalClass}
        orientation={orientation}
        imgSrc={awardItem.imgSrc}
        title={awardItem.title}
        date={awardItem.date}
        subTitles={awardItem.subTitles}
        imgBackgroundColor={awardItem.imgBackgroundColor}
      />)
    )
  }
  </div>
);

export default AwardList;
