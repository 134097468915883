const RELATED_LINKS_SCHEME = [
  {
    icon: 'CriteriaIcon',
    href: '/high-value-mortgage-services',
    title: 'High Value Mortgage Service',
    description: `A professional and personal service dedicated to loans over £1 million.`,
  },
  {
    icon: 'StepByStep',
    href: '/placing-business',
    title: `Placing business`,
    description: `Step-by-step guide to HSBC UK mortgages`
  },
  {
    icon: 'SearchIcon',
    href: '/products/product-finder',
    title: 'Product finder',
    description: `Browse all the mortgage products that match your customer's criteria.`,
  }, 
]; 

export default RELATED_LINKS_SCHEME;