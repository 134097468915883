import React from 'react';

const AwardCard = ({ title, date, subTitles, imgBackgroundColor, imgSrc, additionalClass, orientation}) => (
  <div className={`${(additionalClass && additionalClass) || ''} ${(orientation && orientation) || ''} award-card`}>
    <div className={`${imgBackgroundColor ? 'borderless ' : ''}card`}>
      { imgSrc ? (
        <figure className='card__image'>
          <img
            style={{ backgroundColor: imgBackgroundColor ? imgBackgroundColor : '' }}
            src={imgSrc}
            alt={`${title}\`s award`}
            aria-hidden="true"
            focusable="false"
          />
        </figure>
      ) : (
        <div className='card__image card__image--empty'></div>
      )}
      <div className='card__title'>
        <h2 dangerouslySetInnerHTML={{__html: title.join('<br/>')}}></h2>
        { date && (<p>{ date }</p>)}
        {
          subTitles.map((subTitle, index) => (
            <h3 key={index} dangerouslySetInnerHTML={{__html: subTitle}}></h3>
          ))
        }
      </div>
    </div>
  </div>
);

export default AwardCard;
