import React, { useContext } from "react";
import AwardList from "../award-list/award-list.component";
import AWARDS from "./award-panel.utils";
import { Link } from "gatsby";
import { MenuContext } from "../../context";
import { triggerTealiumEvent } from "../../utils/tealiumUtils";
import { useLocation } from "@reach/router";

const AwardPanel = () => {
  const menuContext = useContext(MenuContext);
  const location = useLocation();

  const handleClick = () => {
    triggerTealiumEvent({
      tealiumEvent: "link",
      pageUrl: location.href,
      eventCategory: "content",
      eventAction: "button",
      eventContent: "Awards",
      pageType: "product information",
      pageSubCategory: "Home",
      rawDataLayer: "10451v5",
    });
  };

  return (
    <section className="awards section">
      <div className="row">
        <div className="col">
          <Link
            onClick={() => handleClick()}
            to="/awards"
            inert={menuContext.isMenuOpen ? "true" : null}
            className="homepage-link-titles"
          >
            <h2 className="section__title">Awards</h2>
          </Link>
        </div>
      </div>
      <div className="row">
        <div className="col awards__card-container">
          <AwardList awardItems={AWARDS} additionalClass="col-xs-12 col-lg-3" orientation="vertical" />
        </div>
      </div>
    </section>
  );
};

export default AwardPanel;
