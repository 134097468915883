import React, { useContext } from 'react';

import AnchorList from '../anchor-list/anchor-list.component';
import { Link } from 'gatsby';

import { MenuContext } from '../../context'

import LATEST_NEWS_SCHEME from './latest-news.utils';

const LatestNews = ({ newsItems = LATEST_NEWS_SCHEME, title = 'News'}) => {
  const menuContext = useContext(MenuContext);

  return (
    <section className='latest-news col'>
      <h2>{title}</h2>
      <h3 className="subtitle">Latest updates</h3>
      <p>Our Press Office does not have access to any customer details and is not
        able to assist with customer enquiries. If you are a broker and would like
        further information, please refer to our <Link to='/broker-support' inert={menuContext.isMenuOpen ? "true" : null}>Broker support</Link> page.
      </p>

      <p>If you are a journalist, please email us at: UKPressOffice@hsbc.co.uk</p>
      <AnchorList
        anchorItems={newsItems}
        additionalClassName='ul-ns'
      />
    </section>
  )
}

export default LatestNews;