import React from 'react';

import HeroBanner from '../hero-banner/hero-banner.component';
import AffordabilityCalculator from '../affordability-calculator/affordability-calculator.component';

const HomeHeader = () => (
  <div className='home-header row'>
    <div className='home-header__hero'>
      <HeroBanner />
    </div>

    <div className='home-header__calculator'>
      <AffordabilityCalculator />
    </div>
  </div>
);

export default HomeHeader;