export const FAQS_SCHEME = [
  {
    title: 'Top 3 FAQs',
    href: '/frequently-asked-questions',
  },
];

export const FAQ_QUESTIONS_SCHEME = [
  {
    title: 'Do I need to notify you if I want to cancel a case?',
    href: '/frequently-asked-questions#doINeed',
  },
  {
    title: 'What proof of income do you need for self-employed customers?',
    href: '/frequently-asked-questions#proofOfIncome',
  },
  {
    title: 'If the term extends beyond retirement age, how do you assess affordability?',
    href: '/frequently-asked-questions#affordability',
  },
  {
    title: 'What\'s your policy on lending to foreign nationals living in the UK?',
    href: '/frequently-asked-questions#foreignLivingInUK',
  },
  {
    title: 'Which documents do you accept as proof of address?',
    href: '/frequently-asked-questions#acceptForProofAddress',
  },
  {
    title: 'Which documents do you accept as proof of identity?',
    href: '/frequently-asked-questions#acceptFoProof',
  },
  {
    title: 'How soon can a customer remortgage after taking out a purchase?',
    href: '/frequently-asked-questions#remortgagePurchase',
  },
  {
    title: 'When can I submit an additional borrowing application?',
    href: '/frequently-asked-questions#additionalBorrowing',
  },
  {
    title: 'What is your policy on new-build properties?',
    href: '/frequently-asked-questions#newBuildProperties',
  },
  {
    title: 'Are overpayments allowed?',
    href: '/frequently-asked-questions#overpaymentsAllowed',
  },
]
